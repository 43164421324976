import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import _ from 'lodash'

import './SidebarJobs.scss'

// Components
import FooterJobs from 'components/Jobs/FooterJobs'
import { TitleAlt, ButtonLineDefault, ContentDefault, ButtonAlt } from 'components/Elements'
import FormModal from 'components/FormModal'

const Sidebar = () => {
  const { sidebarJobs } = useStaticQuery(
    graphql`
      {
        sidebarJobs: wordpressWpComponents(wordpress_id: { eq: 287 }) {
          acf {
            sidebar_content_jobs {
              title_sidebar_jobs
              text_sidebar_jobs
              second_button_sidebar_jobs
              button_sidebar_jobs
            }
          }
        }
      }
    `
  )
  return (
    <div className="sidebar">
      <div className="inner">
        <TitleAlt className="color-text-light">
          <strong>{sidebarJobs.acf.sidebar_content_jobs.title_sidebar_jobs}</strong>
        </TitleAlt>
        <ContentDefault className="mb-3">
          <div dangerouslySetInnerHTML={{ __html: sidebarJobs.acf.sidebar_content_jobs.text_sidebar_jobs }} />
        </ContentDefault>
        <FormModal>
          {sidebarJobs.acf.sidebar_content_jobs.button_sidebar_jobs}
        </FormModal>
        <ButtonLineDefault to="/contact" className="w-75">
          {sidebarJobs.acf.sidebar_content_jobs.second_button_sidebar_jobs}
        </ButtonLineDefault>
      </div>
      <div className="more">
        <TitleAlt>Meer Vacatures</TitleAlt>
        <FooterJobs />
      </div>
    </div>
  )
}

export default Sidebar
