import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import './FormModal.scss'

// Components
import { TitleDefault, ButtonDefault } from 'components/Elements'
import GravityForm from 'components/GravityForm'

const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const SiteModal = ({ children }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  return (
    <div>
      <p className="video-btn">
        <div
          onClick={openModal}
          role="button"
          data-toggle="button"
          aria-pressed="false"
          className="button button-alt w-75 cursor-pointer"
        >
          {children}
        </div>
      </p>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <TitleDefault className="mb-4 text-center text-capitalize">
          {children}
        </TitleDefault>
        <GravityForm id={2} />
      </Modal>
    </div>
  )
}

export default SiteModal
