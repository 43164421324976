import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Img from 'gatsby-image'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BreadCrumbBar,
  ButtonDefault,
  ButtonLineDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import SidebarJobs from 'components/SidebarJobs'
import FormModal from 'components/FormModal'

import 'styles/jobs.scss'

export const postQuery = graphql`
  query($wordpress_id: Int!) {
    post: wordpressWpVacatures(wordpress_id: { eq: $wordpress_id }) {
      title
      acf {
        header_image_detail {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        job_text_detail
        subtitle_job_detail
        title_job_detail
        sidebar_title_job
        job_button_left
        job_button_right
        # titel_leerbedrijf
        # image_leerbedrijf {
        #   localFile {
        #     childImageSharp {
        #       fluid(quality: 100, maxWidth: 150) {
        #         ...GatsbyImageSharpFluid_noBase64
        #       }
        #     }
        #   }
        # }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const JobsTemplate = ({
  pageContext,
  data: {
    post: {
      title,
      acf: {
        header_image_detail,
        job_button_left,
        job_button_right,
        job_text_detail,
        sidebar_title_job,
        subtitle_job_detail,
        title_job_detail,
        // titel_leerbedrijf,
        // image_leerbedrijf,
      },
      yoast_meta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoast_meta }} />
      <div className="jobs-template">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="image-container">
              <BackgroundImage
                fluid={header_image_detail.localFile.childImageSharp.fluid}
              />
            </div>
            <div className="container">
              <h1>{title}</h1>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb
              crumbs={pageContext.breadcrumb.crumbs}
              crumbSeparator="-"
              crumbLabel={title}
            />
          </div>
        </BreadCrumbBar>
        <section className="content-row">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8 pt-5">
                <div className="col-md-12 ">
                  <TitleDefault>
                    <span>{title_job_detail}</span>
                    <br />
                    <strong>{subtitle_job_detail}</strong>
                  </TitleDefault>
                </div>
                <div className="col-md-11">
                  <ContentDefault>
                    <div
                      dangerouslySetInnerHTML={{ __html: job_text_detail }}
                    />
                    {/* {titel_leerbedrijf ? (
                      <>
                        <h3>{titel_leerbedrijf}</h3>
                        <Img
                          fluid={
                            image_leerbedrijf.localFile.childImageSharp.fluid
                          }
                          width="150"
                        />
                      </>
                    ) : (
                      <p><br /></p>
                    )} */}
                  </ContentDefault>
                </div>
              </div>
              <div className="col-lg-4 sidebar p-0 mt-5 mt-lg-0">
                <SidebarJobs />
              </div>
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
      </div>
    </Layout>
  )
}

export default JobsTemplate
